<template>
  <div class="new">
    <div class="title col-lg-6 col-md-8 col-sm-8">
      <img src="~assets/images/icon/newImg.png" alt="" />
    </div>
    <div class="new-content" v-html="content"></div>
  </div>
</template>

<script>
import { getArticle } from "network/article";
export default {
  data() {
    return {
      content: "",
    };
  },
  created() {
    this.getArticle(1);
  },
  methods: {
    getArticle(tid) {
      getArticle(tid).then((res) => {
        this.content = res.data.data.article.content;
      });
    },
  },
};
</script>
<style scoped>
.new {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.title {
  margin: 0 auto;
}
.title img {
  width: 100%;
}
.new-content {
  margin-top: 0.75rem;
  font-size: 0.25rem;
}
.new-content img {
  width: 90%;
  height: auto;
}
.new-content p {
}
</style>